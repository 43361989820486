@import "open-props/style";

body {
    font-family: var(--font-serif);
}

.WordleGrid {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: var(--size-4);
    user-select: none;
    background: var(--gradient-29);
    min-height: 100vh;
}

.Guesses {
    display: flex;
    flex-direction: column;
    gap: var(--size-2);
}

.Word {
    display: flex;
    gap: var(--size-1);
}

.Letter {
    position: relative;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-size: var(--size-fluid-2);
    width: var(--size-fluid-3);
    height: var(--size-fluid-3);
    aspect-ratio: var(--ratio-square);
    border: var(--border-size-4) solid var(--gray-5);
    border-radius: var(--radius-3);
    background: var(--gray-3);
    box-shadow: var(--shadow-4);
}

.Letter textarea {
    position: absolute;
    top: -calc(var(--border-size-4)/2);
    left: -calc(var(--border-size-4)/2);
    width: 100%;
    height: 100%;
    opacity: 0;
    resize: none;
    cursor: default;
}

.WaitingForResponse {
    animation: var(--animation-blink);
}

.ShowInvalidGuessWrap {
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.ShowInvalidGuess {
    font-size: var(--size-6);
    font-weight: var(--font-weight-7);
    width: max-content;
    animation: var(--animation-bounce);
    background: var(--noise-3);
    border-radius: var(--radius-3);
    padding: var(--size-fluid-1);
    box-shadow: var(--shadow-4);
}

.NotShowInvalidGuess {
    position: absolute;
    display: none;
}

.KeyboardGridWrapper {
    width: min(100%, 26rem);
    position: sticky;
    bottom: 0;
}

.KeyboardGrid {
    display: grid;
    grid-template-columns: repeat(11, 1fr);
    justify-content: space-between;
    width: 100%;
    column-gap: var(--size-1);
    row-gap: var(--size-2);
}

.KeyboardButtonFlex {
    display: flex;
    justify-content: space-around;
    margin: 1rem;
}

.KeyboardKey {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    aspect-ratio: var(--ratio-portrait);
    min-height: 2rem;
    border-radius: var(--radius-2);
    background: var(--gray-1);
    width: 100%;
    box-shadow: var(--shadow-2);
    transition: background 1s 2s linear;
}

.KeyboardKey img {
    width: 100%;
}

.Button {
    width: var(--size-11);
    height: var(--size-7);
    border-radius: 500rem;
    border: var(--border-size-2) solid var(--gray-1);
    background: var(--lime-6);
    box-shadow: var(--shadow-4);
    color: white;
}
.Button:hover {
    background: var(--lime-7);
    border-color: var(--gray-2);
}
.Button:active {
    background: var(--lime-8);
    border-color: var(--gray-3);
}

.GuessButton {
    background: var(--lime-6);
}
.GuessButton:hover {
    background: var(--lime-7);
    border-color: var(--gray-2);
}
.GuessButton:active {
    background: var(--lime-8);
    border-color: var(--gray-3);
}

.RemoveButton {
    background: var(--violet-6);
}
.RemoveButton:hover {
    background: var(--violet-7);
    border-color: var(--gray-2);
}
.RemoveButton:active {
    background: var(--violet-8);
    border-color: var(--gray-3);
}

.InvalidGuess {
    position: relative;
}

.CorrectPlace {
    --letterBg: var(--green-7);
    --letterBorderBG: var(--green-9);
    animation: flipLetterSecond 2s linear,
               flipLetterBgCorrect 2s step-end forwards;
}

.LetterExist {
    --letterBg: var(--yellow-7);
    --letterBorderBG: var(--yellow-9);
    animation: flipLetter 2s linear,
               flipLetterBg 2s step-end forwards;
}

.LetterNotExist {
    --letterBg: var(--gray-5);
    --letterBorderBG: var(--gray-7);
    animation: flipLetter 2s linear,
               flipLetterBg 2s step-end forwards;
}

.Keyboard--CorrectPlace {
    background: var(--green-7);
}

.Keyboard--LetterExist {
    background: var(--yellow-7);
}

.Keyboard--LetterNotExist {
    background: var(--gray-5);
}


.Letter:nth-child(1) { animation-delay: 0.0s; }
.Letter:nth-child(2) { animation-delay: 0.1s; }
.Letter:nth-child(2) { animation-delay: 0.2s; }
.Letter:nth-child(3) { animation-delay: 0.3s; }
.Letter:nth-child(3) { animation-delay: 0.4s; }
.Letter:nth-child(4) { animation-delay: 0.5s; }
.Letter:nth-child(5) { animation-delay: 0.6s; }
.Letter:nth-child(6) { animation-delay: 0.7s; }
.Letter:nth-child(7) { animation-delay: 0.8s; }
.Letter:nth-child(8) { animation-delay: 0.9s; }
.KeyboardKey { animation-duration: 3s; }

.TopRow {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.InfoButton {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: white;
    margin: var(--size-2);
    background: var(--blue-7);
    font-size: var(--size-4);
    min-width: var(--size-5);
    width: var(--size-5);
    min-height: var(--size-5);
    height: var(--size-5);
    border-radius: var(--radius-round);
    border: var(--border-size-3) solid white;
    box-shadow: var(--shadow-4);
}
.InfoButton:hover {
    background: var(--blue-8);
}
.InfoButton:active {
    background: var(--blue-9);
}

.ScoreInfo {
    top: 0;
    left: 0;
}

@keyframes flipLetterSecond {
    0%, 100% {
        transform: scale(1,1);
    }
    50% {
        transform: scale(-1,1);
    }
}

@keyframes flipLetter {
    0%, 100% {
        transform: scale(1,1);
    }
    50% {
        transform: scale(-1,1);
    }
}

@keyframes flipLetterBg {
    0% {
    }
    75%, 100% {
        background: var(--letterBg);
        border-color: var(--letterBorderBG);
    }
}

@keyframes flipLetterBgCorrect {
    0% {
    }
    75%, 100% {
        background: var(--letterBg);
        border-color: var(--letterBorderBG);
    }
}
