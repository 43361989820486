@import "open-props/style";

dialog {
    background: var(--teal-4);
    z-index: var(--layer-important);
}

dialog::backdrop {
  background: rgba(0,0,0,.75);
}

dialog p,
dialog h1,
dialog h2 {
    user-select: text;
}
