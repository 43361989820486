:root {
    --loading-start-color: var(--blue-9);
}
.Loading {
    display: flex;
    font-size: var(--size-fluid-4);
    font-weight: bold;
}

.LoadingRed {
    --loading-color: var(--red-7);
    animation: 4s linear infinite loadingframes;
}

.LoadingOrange {
    --loading-color: var(--orange-7);
    animation: 4s linear infinite loadingframes;
}

.LoadingYellow {
    --loading-color: var(--yellow-7);
    animation: 4s linear infinite loadingframes;
}

.LoadingGreen {
    --loading-color: var(--green-7);
    animation: 4s linear infinite loadingframes;
}

.LoadingBlue {
    --loading-color: var(--blue-7);
    animation: 4s linear infinite loadingframes;
}

.LoadingIndigo {
    --loading-color: var(--indigo-7);
    animation: 4s linear infinite loadingframes;
}

.LoadingViolet {
    --loading-color: var(--violet-7);
    animation: 4s linear infinite loadingframes;
}

.LoadingPink {
    --loading-color: var(--pink-7);
    animation: 4s linear infinite loadingframes;
}

.LoadingCyan {
    --loading-color: var(--cyan-7);
    animation: 4s linear infinite loadingframes;
}

@keyframes loadingframes {
    0%, 100% {
        color: var(--loading-start-color);
    }
    40%, 60% {
        color: var(--loading-color);
    }
}
